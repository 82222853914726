import React from 'react'
import { Link } from 'gatsby'

import { rhythm, scale } from '../utils/typography'


export default (props) => {
    if (props.size == 'small') {
        return (
            <nav role="navigation" style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}>
                <h4>
                    <Link to="/tried/" style={props.location.pathname == '/tried/' ? styles.active : styles.inactive}>Tried</Link>
                </h4>
                <h4>
                    <Link to="/about/" style={props.location.pathname == '/about/' ? styles.active : styles.inactive}>About</Link>
                </h4>
            </nav>
        )
    }
    return (
        <nav role="navigation" style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        }}>
            <h3>
                <Link to="/tried/" style={props.location.pathname == '/tried/' ? styles.active : styles.inactive}>Tried</Link>
            </h3>
            <h3>
                <Link to="/about/" style={props.location.pathname == '/about/' ? styles.active : styles.inactive}>About</Link>
            </h3>
        </nav>
    )
}
    
const styles = {
    active: {
        boxShadow: `none`,
        textDecoration: `none`,
        backgroundImage: `none`,
        marginLeft: rhythm(1)
    },
    inactive: {
        boxShadow: `none`,
        textDecoration: `none`,
        backgroundImage: `none`,
        color: 'inherit',
        marginLeft: rhythm(1)
    }
}