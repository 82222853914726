import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Navigation from '../components/navigation'
import Sidebar from '../components/sidebar'

import { rhythm, scale } from '../utils/typography'

class Layout extends React.Component {
  renderLayout = (data) => {
    const { location, children } = this.props
    const title = data.site.siteMetadata.title
    let header

    if (location.pathname === '/' || location.pathname === '/about/' || location.pathname === '/tried/') {
      header = (
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...scale(1.5),
          marginBottom: rhythm(1)
        }}>
          <h1>
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
                backgroundImage: `none`
              }}
              to={`/`}
            >
              {title}
            </Link>
          </h1>
          <Navigation location={location} />
        </div>
      )
    } else {
      header = (
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <h3>
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
                backgroundImage: `none`
              }}
              to={`/`}
            >
              {title}
            </Link>
          </h3>
          <Navigation location={location} size='small' />
        </div>
      )
    }
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        {this.props.showSidebar ? <Sidebar location={this.props.location} /> : null}
        <div
          style={{
            maxWidth: rhythm(24),
            padding: `${rhythm(1)}`,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {header}
          {children}
        </div>
      </div>
    )
  }

  render() {
    return (
      <StaticQuery
        query={detailsQuery}
        render={this.renderLayout}
      />
    )
  }
}

Layout.defaultProps = {
  showSidebar: true
}


export default Layout

const detailsQuery = graphql`
  query DefaultLayoutQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
