import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import styles from './sidebar.module.css'

import { rhythm, scale } from '../utils/typography'


export default (props) => {
    const render = (data) => {
        // ONLY DEPTH OF 2 FOR NOW
        const currSlug = props.location.pathname
        const currPath = props.location.pathname.split('/').filter(s => s.length > 0)
        const currDepth = currPath.length
        
        let routes = {}
        data.allMdx.nodes.forEach((n, i) => {
            const slug = n.fields.slug
            const slugPath = slug.split('/').filter(s => s.length > 0)
            const depth = slugPath.length
            
            if (depth > 0) {
                if (depth == 1) {
                    if (!routes[slugPath[0]]) routes[slugPath[0]] = [];
                } else {

                    if (routes[slugPath[0]]) {
                        routes[slugPath[0]].push(slugPath[1])
                    } else {
                        routes[slugPath[0]] = [slugPath[1]]
                    }
                }
            }
        })
        // show the first level for now
        const sidebar = Object.keys(routes).map((item, i) => {
            if (routes[item].length > 0) {
                return (
                    <div>
                        <p key={item}
                            className={styles.inactive}
                            style={{ fontWeight: 700 }}
                        >
                            {item}
                        </p>
                        {routes[item].map(r => (
                            <p style={{
                                marginLeft: rhythm(1)
                            }}>
                                <Link to={`/${item}/${r}/`} className={currSlug == `/${item}/${r}/` ? styles.active : styles.inactive}>{r.replace('-', ' ')}</Link>
                            </p>
                        ))}
                    </div>
                )
            } 

            return (
                <p key={item} style={{ fontWeight: 700 }}>
                    <Link to={`/${item}/`} className={currSlug == `/${item}/` ? styles.active : styles.inactive}>{item}</Link>
                </p>
            )
        })
        return (
            <nav role="navigation" className={styles.sidebar}>
                {sidebar}
            </nav>
        )
    }
    return (
        <StaticQuery
            query={sidebarQuery}
            render={render} 
        />
    )
}


const sidebarQuery = graphql`
    query sidebarQuery {
        allMdx(
            filter: { 
                fields: { sourceName: { eq: "recipes"} },
                frontmatter: { published: { ne: false } } 
            }
        ) {
            nodes {
                fields {
                    slug
                }
            }
        }
    }

`